declare global {
  interface Window {
    currentRefferalRoute: string
  }
}

// TODO: Find en bedre løsning.
export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) return

  if (window.currentRefferalRoute) {
    if (to.path === from.path) {
      window.currentRefferalRoute = 'direct'
      return
    }

    window.currentRefferalRoute = `${window.location.origin}${from.path}`
    return
  }

  if (document?.referrer === '') {
    window.currentRefferalRoute = 'direct'
    return
  }

  window.currentRefferalRoute = document.referrer
  return
})
