export default defineAppConfig({
  showTeaserDescription: {
    large: false,
    medium: false,
    small: false,
    tiny: false,
    default: false,
    ordered: false,
  },
  teaserItem: {
    extraLarge: {
      labelOnImage: false,
      showTeaserDescription: false,
      showImage: true,
    },
    large: {
      labelOnImage: false,
      showTeaserDescription: true,
      showImage: true,
    },
    medium: {
      labelOnImage: false,
      showTeaserDescription: false,
      showImage: true,
    },
    small: {
      labelOnImage: false,
      showTeaserDescription: false,
      showImage: true,
    },
    tiny: {
      labelOnImage: false,
      showTeaserDescription: false,
      showImage: false,
    },
    default: {
      labelOnImage: false,
      showTeaserDescription: false,
      showImage: true,
    },
    ordered: {
      labelOnImage: false,
      showTeaserDescription: false,
      showImage: false,
    },
    video: {
      labelOnImage: false,
      showTeaserDescription: false,
      showImage: true,
    },
  },
  useHeroBanner: true,
  isPrimaryTextAlignCentered: true,
  withTeaserCategories: true,
  showBurgerMenu: true,
  showLogin: false,
  fallbackHeroImage: true,
  hasShortHeaderLogo: true,
  teaserlist: {
    sliderVideo: {
      hasBackground: 'darkmode',
      fullWidth: false,
    },
  },
  page: {
    category: {
      layout: 'default',
      bannerTemplate: 'default',
      darkmode: false,
      hasStickyBanner: true,
    },
    frontpage: {
      layout: 'default',
      bannerTemplate: 'default',
      darkmode: false,
      hasStickyBanner: true,
    },
    video: {
      layout: 'video',
      bannerTemplate: 'default',
      darkmode: true,
      hasStickyBanner: true,
    },
    authors: {
      layout: 'default',
      bannerTemplate: 'default',
      darkmode: false,
      hasStickyBanner: true,
    },
    login: {
      layout: 'login',
      bannerTemplate: 'default',
      darkmode: false,
    },
  },
  category: {
    layout: 'default',
    bannerTemplate: 'default',
    hasStickyBanner: true,
  },
  siteSpecific: {
    brands: {
      bom: {
        brandSchemaUrl: '/bolig-magasinet',
        icon: {
          tag: 'bolig-magasinet',
          name: 'bom_icon',
        },
      },
      liv: {
        brandSchemaUrl: '/magasinet-liv',
        icon: {
          tag: 'magasinet-liv',
          name: 'liv_icon',
        },
      },
      cos: {
        brandSchemaUrl: '/costume',
        icon: {
          tag: 'costume',
          name: 'cos_icon',
        },
      },
    },
  },
  languageSpecefic: {
    languages: {
      dk: {
        socialShare: [
          {
            type: 'link',
            link: 'https://www.facebook.com/womandk',
            icon: 'facebook',
            description: 'share on facebook',
          },
          {
            type: 'copy',
            icon: 'link',
            style: '',
            description: 'copy link',
          },
        ],
        video: {
          production: {
            id: '354',
          },
          beta: {
            id: '354',
          },
        },
      },
    },
  },

  contentTypes: {
    video: {
      layout: 'default',
      darkmode: true,
      contentRecommendation: 'video',
      hasFrontPageLoaded: false,
      hasStickyBanner: true,
    },
    article: {
      layout: 'default',
      bannerTemplate: 'default',
      contentRecommendation: 'default',
      hasFrontPageLoaded: true,
      hasStickyBanner: true,
    },
    blog: {
      layout: 'blog',
      contentRecommendation: 'default',
      hasFrontPageLoaded: true,
      hasStickyBanner: true,
    },
  },
})
