import type { Script } from '@unhead/schema'

export const cookieConsent = (
  brandCode: string,
  consentStatus: any
): Script => ({
  hid: 'CookieConsent',
  innerHTML: `window.gdprAppliesGlobally=true;(function(){function r(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}else{setTimeout(function(){r(e)},5)}}}function e(n,i,o,c,s){function e(e,t,n,r){if(typeof n!=="function"){return}if(!window[i]){window[i]=[]}var a=false;if(s){a=s(e,r,n)}if(!a){window[i].push({command:e,version:t,callback:n,parameter:r})}}e.stub=true;e.stubVersion=2;function t(r){if(!window[n]||window[n].stub!==true){return}if(!r.data){return}var a=typeof r.data==="string";var e;try{e=a?JSON.parse(r.data):r.data}catch(t){return}if(e[o]){var i=e[o];window[n](i.command,i.version,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:i.callId};r.source.postMessage(a?JSON.stringify(n):n,"*")},i.parameter)}}if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");r("__tcfapiLocator");(function(e,t){var n=document.createElement("link");n.rel="preload";n.as="script";var r=document.createElement("script");r.id="spcloader";r.type="text/javascript";r["async"]=true;r.charset="utf-8";var a="https://sdk.privacy-center.org/"+e+"/loader.js?target_type=notice&target="+t;n.href=a;r.src=a;var i=document.getElementsByTagName("script")[0];i.parentNode.insertBefore(n,i);i.parentNode.insertBefore(r,i)})("fb809c51-e9cd-4e35-a92d-e44bb858841e","${brandCode}")})();`,
  type: 'text/javascript',
  load: () => {
    window.didomiOnReady = window.didomiOnReady || []
    window.didomiOnReady.push(() => consentStatus())
  },
})
