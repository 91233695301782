import type { DirectiveBinding } from 'vue'
import useTracking from '../composables/Tracking'

export default defineNuxtPlugin(nuxtApp => {
  const { trackInteraction } = useTracking()
  const interactionEventListeners = new WeakMap<HTMLElement, EventListener>()
  const interactionEmitEventListeners = new WeakMap<
    HTMLElement,
    EventListener
  >()

  const elementDataWeakMap = new WeakMap<
    HTMLElement,
    { interactionRef: Ref<string | null> }
  >()

  nuxtApp.vueApp.directive('interaction', {
    mounted(el: HTMLElement) {
      const eventHandler = (event: Event) => {
        const {
          detail: { interaction },
        } = event as CustomEvent<{
          interaction: string
          originalEvent: MouseEvent
        }>
        const { componentName, componentType, position } = el.dataset
        if (interaction && componentName) {
          trackInteraction(interaction, position, componentName, componentType)
        }
      }

      el.addEventListener('interaction:event', eventHandler)

      interactionEventListeners.set(el, eventHandler)
    },
    unmounted(el: HTMLElement) {
      const eventHandler = interactionEventListeners.get(el)

      if (eventHandler) {
        el.removeEventListener('interaction:event', eventHandler)
        interactionEventListeners.delete(el)
      }
    },
  })
  nuxtApp.vueApp.directive('interaction-emit', {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
      const interactionRef = ref<string | null>(binding.value)
      elementDataWeakMap.set(el, { interactionRef })

      const interactiomEmitHandler = (event: MouseEvent) => {
        const interactionEvent: CustomEvent<{
          interaction: string
          originalEvent: MouseEvent
        }> = new CustomEvent('interaction:event', {
          bubbles: true,
          detail: {
            originalEvent: event,
            interaction: interactionRef.value || 'click',
          },
        })
        el.dispatchEvent(interactionEvent)
      }
      el.addEventListener('click', interactiomEmitHandler)
      interactionEmitEventListeners.set(
        el,
        interactiomEmitHandler as EventListener
      )
    },
    updated(el: HTMLElement, binding: DirectiveBinding) {
      const elementData = elementDataWeakMap.get(el)
      if (elementData && elementData.interactionRef.value !== binding.value) {
        elementData.interactionRef.value = binding.value
      }
    },
    beforeUnmount(el: HTMLElement) {
      const elementData = elementDataWeakMap.get(el)
      const eventListener = interactionEmitEventListeners.get(el)
      if (elementData) {
        elementDataWeakMap.delete(el)
      }
      if (eventListener) {
        el.removeEventListener('click', eventListener)
        interactionEmitEventListeners.delete(el)
      }
    },
  })
})
