const localeToCountryCodes = {
  da: 'dk',
  sv: 'se',
  nb: 'no',
  nl: 'nl',
  fi: 'fi',
  en: 'gb',
}

export function localeToCountryCode(locale: string): string {
  return localeToCountryCodes[locale]
}

/**
 * Inverse of localeToCountryCode function.
 *
 * @param {String} countryCode
 */
export function countryCodeToLocale(countryCode: string): string | null {
  return (
    Object.keys(localeToCountryCodes).find(
      (key: string) => localeToCountryCodes[key] === countryCode
    ) || null
  )
}

/**
 * Returns the teh locale and country code, in one string, connected by a `-` or `-`
 * I dashed parameter is true. a dashed will be used to join the string
 *
 * @param locale
 * @param dashed
 */
export function localeWithCountryCode(
  locale: string,
  dashed: boolean = false
): string {
  return [locale, localeToCountryCode(locale)?.toUpperCase()].join(
    dashed ? '-' : '_'
  )
}
