import { onMounted, onUnmounted } from 'vue'

const checkDiff: number = 50

type ObservedItem = {
  el: HTMLElement
  callback: { (entry: IntersectionObserverEntry): void }
}

export function useScrollHandler() {
  const scrollPosition = ref<number>(0)
  const scrollDirection = ref<null | string>('up')
  const observedItems: ObservedItem[] = []
  let lastCheckPos = scrollPosition.value

  /**
   * Holds the element-observer, if not on server.
   */
  const observer = import.meta.client
    ? new IntersectionObserver(entries => {
        entries.forEach(entry => {
          const foundItem = observedItems.find(el => el.el === entry.target)
          if (foundItem) {
            foundItem.callback(entry)
          }
        })
      })
    : null

  /**
   * Sets the current scroll position. Is called from a scroll event listener.
   *
   * @return void
   */
  const setScrollPos = (): void => {
    if (import.meta.client) {
      scrollPosition.value =
        document.documentElement.scrollTop || document.body.scrollTop
      checkScrollDirection()
    }
  }

  /**
   * Checks if scroll position exceeds the constant for checking difference.
   * If it does, it sets the direction based upon a last checked scroll position.
   *
   * @return void
   */
  const checkScrollDirection = (): void => {
    if (Math.abs(lastCheckPos - scrollPosition.value) > checkDiff) {
      scrollDirection.value =
        lastCheckPos > scrollPosition.value ? 'up' : 'down'
      lastCheckPos = scrollPosition.value
    }
  }

  /**
   * Function returns the relative scroll value related to the provided element.
   *
   * @param { HTMLElement } el
   * @param { (e: Event, pos: number|null) => void } callback
   */
  function onElementScroll(
    el: HTMLElement,
    callback: (e: Event, pos: number | null) => void
  ): void {
    const relativeScroll = ref<number | null>(null)
    const scrollFunc = (e: Event) =>
      callback(
        e,
        (relativeScroll.value =
          el.getBoundingClientRect()?.top * -1 > 0 &&
          el.getBoundingClientRect()?.top < el.getBoundingClientRect()?.height
            ? el.getBoundingClientRect()?.top * -1
            : null)
      )

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        window.removeEventListener('scroll', scrollFunc)
        if (entry.isIntersecting) {
          window.addEventListener('scroll', scrollFunc)
        }
      })
    })

    observer.observe(el)
  }

  /**
   * Adds an element to being observed. A callback is also provided, that will be called on intersection.
   *
   * @param {HTMLElement} el
   * @param {(entry: IntersectionObserverEntry) => void} cb
   */
  const observeElement = (
    el: HTMLElement,
    cb: (entry: IntersectionObserverEntry) => void
  ): void => {
    if (import.meta.client) {
      observedItems.push({
        el: el,
        callback: cb,
      })
      observer?.observe(el)
    }
  }

  onMounted(() => window.addEventListener('scroll', setScrollPos))
  onUnmounted(() => window.removeEventListener('scroll', setScrollPos))
  useNuxtApp().hook('page:loading:start', () => {
    if (observer) {
      observer.disconnect()
      observedItems.length = 0
    }
  })

  return { scrollPosition, scrollDirection, observeElement, onElementScroll }
}
