import revive_payload_client_aUwAmB9hvX from "/wom/node_modules/.pnpm/nuxt@3.12.4_@types+node@18.19.44_vite@5.4.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_82R4KOw2eP from "/wom/node_modules/.pnpm/nuxt@3.12.4_@types+node@18.19.44_vite@5.4.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yc0DCxHVbh from "/wom/node_modules/.pnpm/nuxt@3.12.4_@types+node@18.19.44_vite@5.4.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_5dnljdJ1tw from "/wom/node_modules/.pnpm/nuxt-site-config@2.2.15_vite@5.4.0_vue@3.4.38/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_5rXa8x7aq0 from "/wom/node_modules/.pnpm/nuxt@3.12.4_@types+node@18.19.44_vite@5.4.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_4Sfc3NJFa0 from "/wom/node_modules/.pnpm/nuxt@3.12.4_@types+node@18.19.44_vite@5.4.0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_5ylHFmT3xr from "/wom/node_modules/.pnpm/nuxt@3.12.4_@types+node@18.19.44_vite@5.4.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_QmbZJTGcBN from "/wom/node_modules/.pnpm/nuxt@3.12.4_@types+node@18.19.44_vite@5.4.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_lZfcZu9Tzo from "/wom/node_modules/.pnpm/@pinia+nuxt@0.5.3_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/wom/wom/.nuxt/components.plugin.mjs";
import prefetch_client_KUzPvdEPyH from "/wom/node_modules/.pnpm/nuxt@3.12.4_@types+node@18.19.44_vite@5.4.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_2ROvcUoNH0 from "/wom/node_modules/.pnpm/@sentry+nuxt@8.28.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_yy7drpsvh2s3p6elfdrjp6mw6u/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/wom/wom/.nuxt/sentry-client-config.mjs";
import plugin_8E5Q1LB4Uh from "/wom/node_modules/.pnpm/@nuxtjs+device@3.1.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import aws_amplify_client_NS5bCs0iFO from "/wom/base/plugins/aws-amplify.client.ts";
import error_handler_jMfEKRgPJl from "/wom/base/plugins/error-handler.ts";
import interaction_client_xbJQar71LJ from "/wom/base/plugins/interaction.client.ts";
import seo_jG4c5iErFd from "/wom/base/plugins/seo.ts";
export default [
  revive_payload_client_aUwAmB9hvX,
  unhead_82R4KOw2eP,
  router_yc0DCxHVbh,
  _0_siteConfig_5dnljdJ1tw,
  payload_client_5rXa8x7aq0,
  navigation_repaint_client_4Sfc3NJFa0,
  check_outdated_build_client_5ylHFmT3xr,
  chunk_reload_client_QmbZJTGcBN,
  plugin_vue3_lZfcZu9Tzo,
  components_plugin_KR1HBZs4kY,
  prefetch_client_KUzPvdEPyH,
  sentry_client_2ROvcUoNH0,
  sentry_client_config_o34nk2sJbg,
  plugin_8E5Q1LB4Uh,
  aws_amplify_client_NS5bCs0iFO,
  error_handler_jMfEKRgPJl,
  interaction_client_xbJQar71LJ,
  seo_jG4c5iErFd
]