<script setup>
  const { name } = defineProps(['name'])

  const component = computed(() => {
    return defineAsyncComponent(
      () => import(`@/assets/svg/${name}.svg?component`)
    )
  })
</script>

<template>
  <component :is="component" />
</template>
