<script setup lang="ts">
  const model = defineModel()

  const {
    type = 'text',
    placeholder = '',
    debounce = false,
  } = defineProps<{
    type?: string
    placeholder?: string
    debounce?: boolean
  }>()

  function debounceFn(fn: (...args: any[]) => void, delay: number) {
    let timeout: ReturnType<typeof setTimeout>
    return (...args: any[]) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => fn(...args), delay)
    }
  }

  // local var to watch it and update "model" with debounce delay
  const localValue = ref(model)
  const debounceUpdateModel = debounceFn((value: string) => {
    model.value = value
  }, 300)

  watch(localValue, newValue => {
    if (debounce) debounceUpdateModel(newValue)
    else model.value = newValue
  })
</script>

<template>
  <div class="border-tone-secondary flex gap-3 border-[2px]">
    <input
      class="h-full w-full bg-[transparrent] p-5 outline-none"
      v-model="localValue"
      :type="type"
      :placeholder="placeholder"
    />
  </div>
</template>
