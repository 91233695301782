import { type BannerData } from '../types/basic'
import banner from '../components/Widgets/Banner.vue'

export function useBannerSlots() {
  const useRichMediaBannerData = ref<BannerData>({
    type: 'banner',
    position: 'rich_media_premium',
  })

  const usePanoramaBannerData = ref<BannerData>({
    type: 'banner',
    position: 'panorama',
  })

  const useInsiderBannerData = ref<BannerData>({
    type: 'banner',
    position: 'insider',
  })

  const useOutsiderBannerData = ref<BannerData>({
    type: 'banner',
    position: 'outsider',
  })

  const useArticleModuleBannerData = ref<BannerData>({
    type: 'banner',
    position: 'articlemodule',
  })

  const UseRichMediaBanner = defineComponent({
    render() {
      return h(banner, { data: { ...useRichMediaBannerData.value } })
    },
  })

  const UsePanoramaBanner = defineComponent({
    render() {
      return h(banner, { data: { ...usePanoramaBannerData.value } })
    },
  })

  const UseInsiderBanner = defineComponent({
    render() {
      return h(banner, { data: { ...useInsiderBannerData.value } })
    },
  })

  const UseOutsiderBanner = defineComponent({
    render() {
      return h(banner, { data: { ...useOutsiderBannerData.value } })
    },
  })

  const UseArticleModuleBanner = defineComponent({
    render() {
      return h(banner, { data: { ...useArticleModuleBannerData.value } })
    },
  })

  return {
    useRichMediaBannerData,
    UseRichMediaBanner,
    usePanoramaBannerData,
    UsePanoramaBanner,
    useInsiderBannerData,
    UseInsiderBanner,
    useOutsiderBannerData,
    UseOutsiderBanner,
    useArticleModuleBannerData,
    UseArticleModuleBanner,
  }
}
