<script setup lang="ts">
  const model = defineModel()

  const emit = defineEmits(['onSearchClick'])

  defineProps<{
    placeholder?: string
  }>()
</script>

<template>
  <div class="border-b-small border-tone-secondary relative w-full py-2">
    <input
      v-model="model"
      class="font-button text-primary placeholder:text-tertiary bg-surface-primary w-full outline-none"
      :placeholder="placeholder"
    />
  </div>
</template>
