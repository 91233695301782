<script setup lang="ts">
  const { data } = defineProps<{ data: object }>()
  const { isMobile } = useDeviceType()

  const { getAllTags, content, pageType } = useContentStore()
  const userStore = useUserStore()

  const subscriber = userStore?.userState === 'Subscriber' || false
  const commercial = content?.commercial || null

  let tagsString = ''

  if (pageType === 'content') {
    const tags = getAllTags()
    const tagsArray = [commercial, ...tags].filter(Boolean) as string[]
    tagsString = tagsArray.join(', ')
  }

  const { position } = data
  const bannerSlot = computed(() => {
    if (['panorama', 'articlemodule'].includes(position) && isMobile) {
      return 'mob'
    } else if (position === 'rich_media_premium' && isMobile) {
      return 'mob_rich_media_premium'
    } else {
      return position
    }
  })
</script>

<template>
  <aside
    v-if="!commercial && !subscriber"
    class="full-content bp-ads relative mx-auto grid w-full justify-center"
  >
    <div class="banner-container">
      <b-a-d
        :slotname="bannerSlot"
        :targeting-tags="tagsString"
        class="bam-ad-slot"
      ></b-a-d>
    </div>
  </aside>
</template>
