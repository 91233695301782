<script setup lang="ts">
  const appConfig = useAppConfig()

  const props = defineProps<{
    description?: string
  }>()

  const centerAlignmentText = appConfig?.isPrimaryTextAlignCentered
</script>

<template>
  <div class="col-span-12 flex grid flex-col gap-5">
    <h2
      class="font-deck-title text-primary"
      :class="{ 'text-center': centerAlignmentText }"
    >
      <slot />
    </h2>
    <p
      v-if="props.description"
      class="font-body text-tertiary"
      :class="{ 'text-center': centerAlignmentText }"
    >
      {{ props.description }}
    </p>
  </div>
</template>
