import { localeWithCountryCode } from '../utils/localeUtils'

export function definePublisher() {

  const config = useRuntimeConfig();

  return defineOrganization({
    name: config?.public?.SITE_NAME,
    url: `https://${useRuntimeConfig()?.public.SITE_URL}`,
    description: useTranslationStore()?.getTranslation('meta.description'),
    logo: defineImage({
      url: `https://${useRuntimeConfig()?.public?.SITE_URL}/logo.png`
    }),
  })
}
