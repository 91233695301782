interface DeviceInfo {
  isDesktop: boolean
  isTablet: boolean
  isMobile: boolean
  [key: string]: any // For additional properties from useDevice
}

type Sizes = {
  width: number
  height: number
}

type DeviceSizes = {
  viewport: Sizes
  screen: Sizes
}

export const useDeviceType = (): DeviceInfo => {
  const { isDesktop, isTablet, isMobile, ...rest }: DeviceInfo = useDevice()

  // Simplified logic for determining currentDevice
  const currentDevice: string = isMobile
    ? 'mobile'
    : isTablet
      ? 'tablet'
      : 'desktop'

  return { currentDevice, isMobile, isTablet, isDesktop, ...rest }
}

export const useDeviceSize = (): DeviceSizes => {
  const viewport: Sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  }
  const screen: Sizes = {
    width: window.screen.width,
    height: window.screen.height,
  }

  return { viewport, screen }
}
