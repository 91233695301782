import {
  COMPOSITE_ACCESS_LEVELS,
  type COMPOSITE_EDITORIAL_TYPES,
  COMPOSITE_TEMPLATES,
  MARKET_CODES,
} from './basic.enums'
import type { Tag } from './helperTypes'

export type DatalayerDataReady = {
  event: string
  environment?: DatalayerEnvironment
  org?: DatalayerOrg
  browser?: DatalayerBrowser
  device?: DatalayerDevice
  content?: DatalayerContent
  user?: DatalayerUser
  engagement?: any
  referrer?: string
  eventParams?: object
}

type DatalayerEnvironment = {
  isProduction?: boolean
  platform: { name: string }
  dataSource?: 'web' | 'app'
}

export type DatalayerOrg = {
  brandCode: string
  market: MARKET_CODES
  businessUnit: string
  subBrand: string | undefined
  service: string
}

export type DatalayerBrowser = {
  viewport: {
    width: number
    height: number
  }
}

type DatalayerDevice = {
  screen: {
    width: number
    height: number
  }
}

export type DatalayerContent = {
  section: string | string[]
  id: DatalayerContentId //the id of the article. Systems refers to an identifier for the system where the id an be looked up to get the full content of the page
  type: string // this is similar to existing contentType, note it should be lowercase.
  index: string //used for continuous scrolling 0 is the first page, 1 is the second page etc. all pages without continuos scroll defaults to 0
  isStandalone: boolean //should be true on all pages without continuous scroll
  location: string
  title: string
  trigger: 'page load' | undefined
  commercialType: 'editorial' | 'free' // TODO check all commercial types
  wordCount: string
  publicationDate: string | undefined
  lastModifiedDate: string | undefined
  category: string | undefined
  topCategory: string | undefined
  author: string
  tags: string[] | []
  tagsHidden: string[] | []
  editorialType: COMPOSITE_EDITORIAL_TYPES
  template: COMPOSITE_TEMPLATES
  contentAccessStatus: string
}

type DatalayerContentId = {
  value: string
  system: string
}

type DatalayerUser = {
  isLoggedIn: boolean
  isSubscriber: boolean
}

export type DataLayerAuthEvent = {
  event: string
  engagement: {
    type: string
    component: {
      name: string
      position: string
      eventParams: {
        category: string
        errorMessage: string | undefined
      }
    }
  }
}

export enum AuthStatusEvent {
  LOGIN_SUBMIT = 'login submit',
  LOGIN_SUCCESS = 'login success',
  LOGIN_FAILURE = 'login error',
  LOGOUT_SUCCESS = 'logout success',
  LOGOUT_FAILURE = 'login error',
}

export enum AuthFormStatus {
  SUBMIT = 'form submit',
  SUCCESS = 'form success',
  ERROR = 'form error',
}
