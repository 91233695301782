/**
 * Transform
 *
 * @param apiUrl
 */
export function basicUrl(apiUrl: string) {
  return `https://${apiUrl}/wp-json`
}

/**
 * Used to run input through a series of functions.
 *
 * @param fns
 */
export function pipe(...fns: any[]): any {
  return (x: any) => fns.reduce((v, f) => f(v), x)
}

/**
 * Used to retrieve only certain keys in an object.
 */
export function objectPick(obj: object, keys: string[]): any[] {
  return keys.filter(key => Object.keys(obj).includes(key)).map(key => obj[key])
}

/**
 * Returns a list of all site specific brands, that has icon defined in the app config.
 */
export function getBrandIcons(): any {
  return Object.entries(useAppConfig().siteSpecific?.brands || {})
    .map(([_, val]) => (val?.icon ? { [val.icon?.tag]: val.icon?.name } : null))
    .filter(brand => !!brand)
    .reduce((acc, cur) => ({ ...acc, ...cur }), {})
}

export const languageIdentifierMap = {
  se: 'bse',
  dk: 'bod',
  no: 'bno',
  fi: 'bfi',
  nl: 'bnl',
  gb: 'bgb',
}

export const languageCodeMap = {
  se: 'sv',
  dk: 'da',
  no: 'nb',
  fi: 'fi',
  nl: 'nl',
  gb: 'en',
  en: 'en',
}

export function chunkArray(arr: [], perChunk: number): [] {
  return arr.reduce((all, one, i) => {
    const ch = Math.floor(i / perChunk)
    all[ch] = [].concat(all[ch] || [], one)
    return all
  }, [])
}

export const componentMapProp = 'display_hint'

export const openWindow = (
  url: string,
  { width = 520, height = 520 } = {}
): void => {
  window.open(
    url,
    '_blank',
    `location=yes,height=${height},width=${width},scrollbars=yes,status=yes`
  )
}
