export default {
  "category-default": () => import("/wom/base/layouts/Category/Default.vue").then(m => m.default || m),
  "composite-blog": () => import("/wom/base/layouts/Composite/Blog.vue").then(m => m.default || m),
  "composite-default": () => import("/wom/base/layouts/Composite/Default.vue").then(m => m.default || m),
  "composite-gradient": () => import("/wom/base/layouts/Composite/Gradient.vue").then(m => m.default || m),
  "error-not-found": () => import("/wom/base/layouts/Error/NotFound.vue").then(m => m.default || m),
  "error-server": () => import("/wom/base/layouts/Error/server.vue").then(m => m.default || m),
  "page-authors": () => import("/wom/base/layouts/Page/Authors.vue").then(m => m.default || m),
  "page-default": () => import("/wom/base/layouts/Page/Default.vue").then(m => m.default || m),
  "page-favourites": () => import("/wom/base/layouts/Page/Favourites.vue").then(m => m.default || m),
  "page-frontpage": () => import("/wom/base/layouts/Page/Frontpage.vue").then(m => m.default || m),
  "page-login": () => import("/wom/base/layouts/Page/Login.vue").then(m => m.default || m),
  "page-profile": () => import("/wom/base/layouts/Page/Profile.vue").then(m => m.default || m),
  "page-signup": () => import("/wom/base/layouts/Page/Signup.vue").then(m => m.default || m),
  "page-video": () => import("/wom/base/layouts/Page/Video.vue").then(m => m.default || m),
  "tags-default": () => import("/wom/base/layouts/Tags/Default.vue").then(m => m.default || m)
}