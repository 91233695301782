export default defineNuxtRouteMiddleware((to, from) => {
  const config = useRuntimeConfig()

  const loginPath = config.public.LOGIN_URL
  const signupPath = config.public.SIGNUP_URL

  if (import.meta.server) return

  if (to?.path !== signupPath && to.path !== loginPath) {
    localStorage.setItem('returnUrl', to.path)
  }
})
