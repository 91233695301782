import type { Script } from '@unhead/schema'

export const gptScript = (): Script => ({
  hid: 'gpt-script-id',
  src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
  id: 'gpt-script-id',
})

export const rubiconScript = (): Script => ({
  hid: 'rubicon-script-id',
  src: 'https://micro.rubiconproject.com/prebid/dynamic/12954.js?site=BP_DK',
  id: 'rubicon-script-id',
  'data-vendor': 'iab:52',
})
function isBannerCollapsed(event: CustomEvent): boolean {
  const size = event.detail.size
  return (size[0] === 1 && size[1] === 1) || (size[0] === 1 && size[1] === 2)
}

export const adnamiScript = (domain: string, lang: string): Script => ({
  hid: 'adnami-script-id',
  src: `https://functions.adnami.io/api/macro/adsm.macro.${domain}.${lang}.js`,
  type: 'module',
  id: 'adnami-script-id',
})

export const badScript = (env: string): Script => ({
  hid: 'bad-script-id',
  src:
    env === 'production'
      ? 'https://assets.bonad.io/bundles/bad.js'
      : 'https://assets.bonad.io/dfp/stage/bad.js',
  type: 'module',
  id: 'bad-script-id',
  onload: () => {
    window.addEventListener('bad:slotRenderEnded', event => {
      const targetId = (event.target as HTMLElement).id
      const highImpactWrapper = document.getElementById('high-impact-banner')
      if (
        targetId.includes('rich_media_premium') &&
        !isBannerCollapsed(event as CustomEvent)
      ) {
        // Do something if the id includes "rich_media_premium"
        highImpactWrapper?.classList.add('bp-topbanner')
      } else if (targetId.includes('rich_media_premium')) {
        highImpactWrapper?.classList.remove('bp-topbanner')
      }
    })
  },
})

export const trackingScript = (domain: string, id: string): Script => ({
  hid: 'tracking-script-id',
  src: `https://tracking.${domain}/gtm.js?id=${id}`,
  type: 'text/javascript',
})

export const pianoScript = (
  id: string,
  sandbox: string,
  xcenseId: string
): Script => ({
  hid: 'piano',
  src: 'https://cdn.tinypass.com/api/tinypass.min.js',
  type: 'text/javascript',
  charset: 'utf-8',
  async: true,
  onload: () => {
    window.tp = window.tp || []
    window.tp.push(['setAid', id])
    window.tp.push(['setCxenseSiteId', xcenseId])
    if (sandbox === 'true') {
      window.tp.push(['setSandbox', 'true'])
      window.tp.push(['setEndpoint', 'https://sandbox.tinypass.com/api/v3'])
    }
  },
})

export const cxenseScript = (cxenseId: string): Script => ({
  hid: 'cxense',
  src: 'https://cdn.cxense.com/cx.cce.js',
  defer: true,
  ssr: false,
  onload: () => {
    window.cX = window.cX || {}
    window.cX.callQueue = window.cX.callQueue || []
    window.cX.callQueue.push(['setSiteId', cxenseId])
    window.cX.callQueue.push(['sendPageViewEvent'])
  },
})
