import { badScript, rubiconScript } from '../constants/trackingScripts'

/**
 * Interface representing an advertisement.
 */
interface Ad {
  adNode: Element
  published: boolean
  nodeId: number
}

/**
 * Interface for configuration settings.
 */
interface Config {
  pageType: string
  device: string
  spa: boolean
  targeting?: {
    tags: string[]
  }
}

/**
 * Interface for the Bau object with methods for ad display and initialization.
 */
interface Bau {
  displayAds(target: HTMLElement & { published: boolean }): unknown
  init: (data: any, config: Config) => void
  preloadAds: (data: any, config: Config) => void
  // Add other methods of Bau as needed
}

/**
 * Extends the global Window interface to include the Bau object.
 */
declare global {
  interface Window {
    Bau: Bau
  }
}

/**
 * Hook for using Bau functionality within a Vue component.
 *
 * @returns An object containing the BadInit function.
 */
export function useBau() {
  /**
   * Uses the device type hook to determine the current device.
   */
  const { currentDevice } = useDeviceType()

  const addDeviceType = () => {
    useHead({
      meta: [{ hid: 'bad:device', name: 'bad:device', content: currentDevice }],
    })
  }

  const addTags = () => {
    const contentStore = useContentStore()
    const { getAllTags, content } = contentStore

    const commercial = content?.commercial?.type || null

    const tags = getAllTags()
    const tagsArray = [commercial, ...tags].filter(Boolean) as string[]
    const tagsString = tagsArray.join(', ')

    if (tagsArray.length > 0) {
      useHead({
        meta: [{ hid: 'bad:tags', name: 'bad:tags', content: tagsString }],
      })
    } else {
      useHead({
        meta: [{ hid: 'bad:tags', name: 'bad:tags', content: '' }],
      })
    }
  }

  const addPageType = (pageType: string) => {
    useHead({
      meta: [
        {
          hid: 'bad:pageType',
          name: 'bad:pageType',
          content: pageType,
        },
      ],
    })
  }

  /**
   * Initializes the Bau object with configuration and BAM data, then sets up ads.
   */
  const BadInit = async () => {
    const { $seo } = useNuxtApp()
    const envConfig = useRuntimeConfig()

    const env = envConfig.public.SITE_ENV
    $seo.addScript('rubicon', rubiconScript())
    $seo.addScript('bad', badScript(env))
    $seo.updateSeo()
  }

  return { BadInit, addDeviceType, addTags, addPageType }
}
