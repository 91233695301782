import { UserState } from '../types/userTypes'
import { useFetch } from '#imports'

export const useUserStore = defineStore('user', () => {
  const userState = ref<UserState>(UserState.anonymous)
  const user = ref<any>(null)
  const loggedInUrlRedirect = ref<string | null>(null)
  const userFavorites = ref<any[]>([])

  const { apiUrl } = useApiUrl()
  const url = basicUrl(apiUrl.value)

  const loadState = () => {
    if (!import.meta.env.SSR) {
      const savedState = sessionStorage.getItem('userStore')
      if (savedState) {
        const parsedState = JSON.parse(savedState)
        userState.value =
          (parsedState.userState as UserState) || UserState.anonymous
      }
    }
  }

  const saveState = () => {
    if (!import.meta.env.SSR) {
      const stateToSave = {
        userState: userState.value,
      }
      sessionStorage.setItem('userStore', JSON.stringify(stateToSave))
    }
  }

  watch(
    [userState, user, loggedInUrlRedirect, userFavorites],
    () => {
      saveState()
    },
    { deep: true }
  )

  const setUserState = (newValue: UserState): void => {
    userState.value = newValue
  }
  const setUser = (newValue: any): void => {
    user.value = newValue
  }

  const setLoggedInUrlRedirect = (newValue: any): void => {
    loggedInUrlRedirect.value = newValue
  }

  const getFavourites = async (userSub: string) => {
    const { data } = await useFetch(`${url}/app/user/favourites/${userSub}`)
    userFavorites.value = data.value
  }

  const addToFavourites = async (userSub: string, article_id: string) => {
    const endpoint = `${url}/app/user/favourites/add/${userSub}`
    await $fetch(endpoint, {
      method: 'POST',
      body: {
        article_id,
      },
    })
    if (!userFavorites.value) {
      userFavorites.value = []
    }
    userFavorites?.value?.data.push(article_id)
  }

  const deleteFromFavourites = async (userSub: string, article_id: string) => {
    const endpoint = `${url}/app/user/favourites/delete/${userSub}`
    await $fetch(endpoint, {
      method: 'POST',
      body: {
        article_id,
      },
    })
    if (!userFavorites?.value?.data) {
      userFavorites.value = []
    }
    userFavorites.value = userFavorites?.value?.data.filter(
      id => id !== article_id
    )
  }

  loadState()

  return {
    userState,
    setUserState,
    user,
    setUser,
    loggedInUrlRedirect,
    setLoggedInUrlRedirect,
    userFavorites,
    getFavourites,
    addToFavourites,
    deleteFromFavourites,
  }
})

export default useUserStore
