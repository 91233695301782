<script setup lang="ts">
  import DefaultInput from '../../Partials/InputField/DefaultInput.vue'
  import SearchInput from '../../Partials/InputField/SearchInput.vue'
  import MenuSearchInput from '../../Partials/InputField/MenuSearchInput.vue'

  const model = defineModel()

  const componentMap: ComponentMap = {
    text: DefaultInput,
    password: DefaultInput,
    email: DefaultInput,
    search: SearchInput,
    menuSearch: MenuSearchInput,
  }

  const { type = 'text' } = defineProps<{
    type?: string
  }>()

  const selectedComponent = computed(() => componentMap[type])
</script>

<template>
  <component :is="selectedComponent" v-model="model" :type="type" />
</template>
