import validate from "/wom/node_modules/.pnpm/nuxt@3.12.4_@types+node@18.19.44_vite@5.4.0/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/wom/base/middleware/auth.global.ts";
import navigate_45logger_45global from "/wom/base/middleware/navigateLogger.global.ts";
import route_45url_45global from "/wom/base/middleware/routeUrl.global.ts";
import manifest_45route_45rule from "/wom/node_modules/.pnpm/nuxt@3.12.4_@types+node@18.19.44_vite@5.4.0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  navigate_45logger_45global,
  route_45url_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}