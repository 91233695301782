import type { Script } from '@unhead/schema'

export type SeoScriptType = {
  name: string
  script: Script
  inserted: boolean
}

/**
 * Holds all tracking scripts, and the likes for the page.
 * All values are added as children, to the <head> tag of the page.
 * Should only be manipulated trough helper functions.
 */
export const scripts: SeoScriptType[] = <SeoScriptType[]>[]

/**
 * Simply returns the local scripts array.
 * Should be used instead of directly accessing the scripts array.
 *
 * @return SeoScriptType[]
 */
export function getScripts(): SeoScriptType[] {
  return scripts
}

/**
 * Returns all locally saved un-inserted scripts.
 *
 * @return SeoScriptType[]
 */
export function getUnInsertedScripts(): SeoScriptType[] {
  return getScripts().filter((script: SeoScriptType) => !script.inserted)
}

/**
 * Checks if script with a given name, is already in array.
 *
 * @param { string } name
 * @return boolean
 */
export function scriptExists(name: string): boolean {
  return !!getScripts().find((script: SeoScriptType) => script.name === name)
}

/**
 * Adds a script instance to the local scripts array.
 * Will check if a script with the given name already exists, if so, it won't be added.
 * An 'inserted' value is also added, for keeping duplicates away from the page.
 *
 * @param { string } name
 * @param { Script } script
 * @return { null | number }
 */
export function addScript(name: string, script: Script): null | number {
  return scriptExists(name)
    ? null
    : getScripts().push({
        name,
        script,
        inserted: false,
      })
}

/**
 * Inserts locally saved scripts, that are not already inserted.
 * Then sets the scripts inserted value to true, to avoid duplicates.
 *
 * @return void
 */
export function prepareScripts(): Script[] {
  const scripts = getUnInsertedScripts().map(
    (script: SeoScriptType) => script.script
  )

  // Update scripts to being inserted
  getUnInsertedScripts().forEach(
    (script: SeoScriptType) => (script.inserted = true)
  )

  return scripts
}

export function resetScripts(): void {
  getScripts().length = 0
}
