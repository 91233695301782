export enum UserState {
  JustRegister = 'JustRegister',
  anonymous = 'Anonymous',
  subscriber = 'Subscriber',
}

export type User = {}

export type AuthResponse = {
  status: number
  statusText?: string
}
