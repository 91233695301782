export enum MARKET_CODES {
  dk = 'dk',
  se = 'se',
  no = 'no',
  fi = 'fi',
  nl = 'nl',
  de = 'de',
}

export enum COMPOSITE_ACCESS_LEVELS {
  free = 'free',
  locked = 'locked',
  none = '',
}

export enum COMPOSITE_SERVICES {
  maincontent = 'main content',
  shop = 'shop',
  quiz = 'quiz',
  lead = 'lead',
  test = 'test',
  fordelszonen = 'fordelszonen',
  routeplanner = 'routeplanner',
  blog = 'blog',
  corporate = 'corporate',
}
