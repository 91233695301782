<script setup lang="ts">
  import { computed, defineProps } from 'vue'
  import LinkTo from '~/components/LinkTo.vue'

  const props = defineProps({
    direction: {
      type: String,
      default: 'right',
    },
    size: {
      type: String,
      default: 'default',
    },
    type: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: null,
    },
    hash: {
      type: String,
      default: null,
    },
    query: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
  })

  const directions = {
    up: 270,
    down: 90,
    left: 180,
  }

  const activeState = computed(() => props.active || false)

  const rotation = computed(() => `${directions[props.direction] ?? 0}deg`)

  const typeClass = computed(() => {
    switch (props.type) {
      case 'primary':
        if (activeState.value) {
          return 'bg-button-secondary border-small text-button-primary  border-tone-primary '
        } else {
          return 'bg-button-primary hover:bg-accent-primary hover:text-button-primary border-small text-button-secondary hover:text-button-primary border-tone-primary'
        }
      // Add more cases if needed
      default:
        return ''
    }
  })

  const isDisabled = computed(() => props.disabled)
</script>

<template>
  <component
    :is="props.link ? LinkTo : 'button'"
    :class="[
      'aspect-1/1 flex h-[32px] w-[32px] items-center justify-center rounded-[100px] transition-all disabled:pointer-events-none disabled:opacity-50 lg:h-[34px] lg:w-[34px]',
      typeClass,
    ]"
    :style="{
      transform: 'rotate(' + rotation + ')',
    }"
    :disabled="isDisabled"
    :link="props.link"
    :query="props.query"
  >
    <slot />
  </component>
</template>

<style>
  button:hover svg {
    @apply fill-button-secondary;
  }

  button svg {
    @apply fill-button-primary;
  }
</style>
