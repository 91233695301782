export function useApiUrl() {
  const config = useRuntimeConfig()
  const route = useRoute()
  const previewKeyword = 'preview=true'

  // Initialize apiUrl with the default value
  const apiUrl = ref(config.public.API_URL || 'api.woman.dk')

  // Check if preview mode is enabled
  if (route.fullPath.includes(previewKeyword)) {
    apiUrl.value = apiUrl.value.replace('api', 'admin')
  }

  return {
    apiUrl: computed(() => apiUrl.value),
  }
}
