<script setup lang="ts">
  import PartialsTeaserListTheme from './TeaserListTheme.vue'
  const { teaserData } = defineProps<{ teaserData: TeaserListWrapper }>()

  const categoryStyle = computed(() => {
    if (teaserData?.category) {
      return {
        '--category-primary-color':
          teaserData?.category?.text_color ||
          teaserData?.data?.parent?.data?.text_color,
        '--category-secondary-color':
          teaserData?.category?.color || teaserData?.data?.parent?.data?.color,
      }
    }
    return {}
  })
</script>

<template>
  <div class="full-content themed bp-main-grid" :style="categoryStyle">
    <div
      class="bg-surface-primary full-content lg:main-content text-primary px-4 py-11 lg:px-11 lg:pb-11 lg:pt-16"
    >
      <PartialsTeaserListTheme
        class="col-span-full m-[0]"
        :teaserData="teaserData"
      />
    </div>
  </div>
</template>
