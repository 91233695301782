
import * as imgixRuntime$P0Skq3gfwx from '/wom/node_modules/.pnpm/@nuxt+image@1.7.0/node_modules/@nuxt/image/dist/runtime/providers/imgix'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imgix",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['imgix']: { provider: imgixRuntime$P0Skq3gfwx, defaults: {"baseURL":"https://images-bonnier.imgix.net/files/wom"} }
}
        