import { getTeaserWithType } from '../../utils/seoUtils'
import { titleCase } from '../../utils/stringUtils'
import type { AuthorData } from '../../types/helperTypes'
import { dateTimeIso } from '../../utils/datetimeUtils'
import { languageIdentifierMap } from '../../utils'
import {
  countryCodeToLocale,
  localeToCountryCode,
  localeWithCountryCode,
} from '../../utils/localeUtils'

const MAX_DESCRIPTION_LENGTH = 152
export const FALLBACK_SEO_TEXT = 'Translations not setup properly'

export type SeoMetaTag = {
  name: string
  content: any
  custom?: boolean
  hid?: string
}

/**
 * Holds all the meta tags that should be shown on the page.
 * All values are added as children, to the <head> tag of the page.
 * Should only be manipulated via. helper functions.
 *
 * @type { SeoMetaTag[] }
 */
export const metaTags: SeoMetaTag[] = <SeoMetaTag[]>[]
export const lockedMetaTags: SeoMetaTag[] = <SeoMetaTag[]>[]

/**
 * Simply returns the local metaTags array.
 * Should be used instead of directly accessing the metaTags array.
 *
 * @return SeoMetaTag[]
 */
export function getMetaTags(): SeoMetaTag[] {
  return [...metaTags, ...lockedMetaTags]
}

/**
 * Returns all locally saved custom meta tags.
 *
 * @return SeoMetaTag[]
 */
export function getCustomMetaTags(): SeoMetaTag[] {
  return getMetaTags().filter((tag: SeoMetaTag) => tag.custom)
}

/**
 * Checks if meta tag with a given name, is already in array.
 *
 * @param { string } name
 * @return boolean
 */
export function metaTagExists(name: string): boolean {
  return !!getMetaTags().find(tag => tag.name === name)
}

/**
 * Used to add an instance to the local metaTag array.
 * The name parameter is used as a unique identifier. Only one instance pr. name is possible.
 * The custom parameter is used, when we don't want the Nuxt Seo composable to manipulate the key.
 * Return the new index of added tag, or null if not added.
 *
 * @param { string } name
 * @param { any } content
 * @param { boolean } custom
 * @param { boolean } locked
 * @return { number | null }
 */
export function addMetaTag(
  name: string,
  content: any,
  custom: boolean = false,
  locked: boolean = false
): number | null {
  return metaTagExists(name)
    ? null
    : (locked ? lockedMetaTags : metaTags).push({ name, content, custom })
}

/**
 * Returns all locally saved meta tags that are not custom.
 * If a meta tag has 'custom' set to true, it will not be added via. the
 * UseServerSeoMeta composable, since it will alter the key value of the tag.
 *
 * @return void
 */
export function prepareMetas(): any[] {
  useSeoMeta(
    getMetaTags()
      .filter((tag: SeoMetaTag) => !tag.custom)
      .reduce(
        (acc, cur: SeoMetaTag) => ({ ...acc, [cur.name]: cur.content }),
        {}
      )
  )

  return getCustomMetaTags().map((tag: SeoMetaTag) => ({
    name: tag.name,
    content: tag.content,
  }))
}

function setGlobals(content: any): void {
  const cxenseLangId =
    languageIdentifierMap[useRuntimeConfig()?.public?.MARKET_CODE]

  // If page should be hidden from robots
  if (
    (content?.hide_in_sitemap ||
      (useContentStore().pageType === 'search' &&
        useRoute()?.query?.s?.length) ||
      useContentStore()?.content,
    useContentStore()?.content?.kind === 'Shell')
  ) {
    addMetaTag('robots', 'noindex, follow, max-image-preview:large')
  } else {
    addMetaTag('robots', 'index, follow, max-image-preview:large')
  }

  addMetaTag('ogTitle', generateMetaTitle(content))
  addMetaTag('ogType', content?.kind?.toLowerCase())
  addMetaTag(
    'ogLocale',
    localeWithCountryCode(
      content?.locale ||
        countryCodeToLocale(useRuntimeConfig().public.MARKET_CODE) ||
        ''
    )
  )
  setDescription(content)
  setOgDescription(content)
  addMetaTag('ogUrl', content?.canonical_url)
  addMetaTag('encoding', { charset: 'utf-8' })
  addMetaTag('handheldFriendly', 'true', true)
  addMetaTag('mobileWebAppCapable', 'true')
  addMetaTag('appleMobileWebAppCapable', 'true')
  addMetaTag('appleMobileWebAppStatusBarColor', 'black')
  addMetaTag('acceptCa', {
    'http-equiv': 'accept-ch',
    content: 'DPR, Width, Viewport-Width',
  })
  addMetaTag('xUaCapable', {
    'http-equiv': 'accept-ch',
    content: 'DPR, Width, Viewport-Width',
  })

  addMetaTag('cXenseParse:articleid', content?.id, true)
  addMetaTag(
    `cXenseParse:${cxenseLangId}-language`,
    content?.locale || content?.language,
    true
  )
  addMetaTag('cXenseParse:title', generateMetaTitle(content), true)
  addMetaTag(
    'cXenseParse:description',
    getDescriptionWithFallback(content, 'default'),
    true
  )
  addMetaTag(
    `cXenseParse:${cxenseLangId}-brand`,
    (useRuntimeConfig()?.public?.BRAND_CODE || '').toUpperCase(),
    true
  )
  addMetaTag(
    `cXenseParse:${cxenseLangId}-country`,
    useRuntimeConfig()?.public?.MARKET_CODE,
    true
  )
  addMetaTag('cXenseParse:bod-pagetype', content?.kind?.toLowerCase(), true)
  addMetaTag(
    `cXenseParse:${cxenseLangId}-danishsearchtitle`,
    content?.translations?.da?.title,
    true
  )
}

/**
 * Sets the meta tags for a 'Page' page-type.
 *
 * @param content
 * @return void
 */
function setPageTags(content: any): void {}

/**
 * Sets the meta tags for a 'Composite' page-type.
 *
 * @param content
 */
function setCompositeTags(content: any): void {
  addMetaTag(
    'articleAuthor',
    titleCase(content?.author?.name || ''),
    ...(content?.other_authors || []).map((author: AuthorData) =>
      titleCase(author.name || '')
    )
  )

  addMetaTag('articleSection', content?.category?.data?.name)
  addMetaTag('articlePublishedTime', dateTimeIso(content?.published_at))
  addMetaTag('articleModifiedTime', dateTimeIso(content?.updated_at))
}

/**
 * Fetches a given default description from content. If not found, a
 * series of fallbacks are looked for.
 *
 * @param content
 * @param defaultType
 * @return string
 */
function getDescriptionWithFallback(content: any, defaultType: string) {
  return (
    getTeaserWithType(content?.teasers?.data, defaultType)?.description ||
    getTeaserWithType(content?.teasers?.data || [], 'default')?.description ||
    content?.description ||
    useTranslationStore().translations?.meta?.description ||
    FALLBACK_SEO_TEXT
  )
}

/**
 * Will find the correct fallback value, and shorten it to fit the max
 * length of a description text. If max length is exceeded, we add ellipsis
 * to the end of the string.
 *
 * @param content
 * @return void
 */
function setDescription(content: any): void {
  addMetaTag('description', getDescriptionWithFallback(content, 'seo'))
}

/**
 * Set's the og:description meta tag. Same logic as normal description meta tag
 * function is applied, but facebook teaser is looked for first.
 *
 * @param content
 * @return void
 */
function setOgDescription(content: any): void {
  addMetaTag('ogDescription', getDescriptionWithFallback(content, 'facebook'))
}

export function generateMetaTitle(content: any): string {
  return (
    getTeaserWithType(
      content?.tag?.teasers?.data || content?.teasers?.data || [],
      'seo'
    )?.title ||
    content?.title ||
    content?.name ||
    getTeaserWithType(
      content?.tag?.teasers?.data || content?.teasers?.data || [],
      'default'
    )?.title ||
    useTranslationStore().translations?.meta?.title ||
    FALLBACK_SEO_TEXT
  )
}

export function generateOgTitle(content: any): string {
  return (
    getTeaserWithType(
      content?.tag?.teasers?.data || content?.teasers?.data || [],
      'facebook'
    )?.title ||
    content?.title ||
    content?.name ||
    getTeaserWithType(
      content?.tag?.teasers?.data || content?.teasers?.data || [],
      'default'
    )?.title ||
    useTranslationStore().translations?.meta?.title ||
    FALLBACK_SEO_TEXT
  )
}

export function setCategoryTags(content: any): void {}

export function setTagTags(content: any): void {}

export function setSubCategoryTags(content: any): void {}

export function setSearchTags(content: any): void {}

export function setAuthTags(content: any): void {}

export function setPageTypeMetaTags(content: any) {
  setGlobals(content)

  switch (useContentStore().pageType) {
    case 'page':
      setPageTags(content)
      break
    case 'content':
      setCompositeTags(content)
      break
    case 'category':
      setCategoryTags(content)
      break
    case 'subcategory':
      setSubCategoryTags(content)
      break
    case 'search':
      setSearchTags(content)
      break
    case 'tag':
      setTagTags(content)
      break
    case 'author':
      setAuthTags(content)
      break
  }
}

/**
 * Function resets meta tags.
 *
 * @return void
 */
export function resetMetaTags(): void {
  metaTags.length = 0
}
