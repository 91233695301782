declare global {
  /**
   * Extends the Window interface to include Didomi properties for consent management.
   */
  interface Window {
    didomiEventListeners: Array<{
      event: string
      listener: (context?: any) => void
    }>
    didomiOnReady: Array<() => void>
    Didomi: {
      [x: string]: any
      getUserConsentStatusForVendor: (vendorId: string) => boolean
      getObservableOnUserConsentStatusForVendor: (vendorId: string) => {
        subscribe: (callback: (consentStatusForVendor: boolean) => void) => void
      }
      getUserStatus: () => {
        vendors: {
          consent: {
            enabled?: string[]
            disabled?: string[]
          }
        }
      }
    }
  }
}

const ConsentInteraction: Ref<boolean> = ref(false)

/**
 * Hook to manage and interact with Didomi consent statuses.
 * Provides methods to subscribe to consent changes and check consent statuses.
 *
 * @returns An object containing reactive consent states and functions to interact with them.
 */
export function useDidomiConsent() {
  const vendorConsentStatus: Ref<boolean> = ref(undefined)

  /**
   * Subscribes to general consent changes.
   *
   * @param callback - The function to call when the consent status changes.
   */
  const subscribeToGeneralConsentChanges = (callback: () => void): void => {
    window.didomiEventListeners = window.didomiEventListeners || []
    window.didomiEventListeners.push({
      event: 'consent.changed',
      listener: (context?: any) => {
        checkConsentStatus()
        callback()
      },
    })
  }

  /**
   * Subscribes to consent changes for a specific vendor.
   *
   * @param vendorId - The ID of the vendor to subscribe to.
   * @param callback - The function to call when the vendor's consent status changes.
   */
  const subscribeToVendorConsentChanges = (
    vendorId: string,
    callback: (status?: boolean) => void
  ): void => {
    window.didomiOnReady = window.didomiOnReady || []
    window.didomiOnReady.push(() => {
      const Didomi = window.Didomi
      const initialStatus = Didomi.getUserConsentStatusForVendor(vendorId)
      vendorConsentStatus.value = !!initialStatus

      Didomi.getObservableOnUserConsentStatusForVendor(vendorId).subscribe(
        (consentStatusForVendor: boolean) => {
          vendorConsentStatus.value = consentStatusForVendor
          callback(consentStatusForVendor)
        }
      )
    })
  }

  /**
   * Sets the user interaction with the consent.
   *
   * @param interaction - A boolean indicating whether the user has interacted with the consent.
   */
  const setConsentInteraction = (interaction: boolean): void => {
    ConsentInteraction.value = interaction
  }

  /**
   * Checks the overall consent status.
   *
   * @returns A boolean indicating whether consent items are present.
   */
  const checkConsentStatus = (): boolean => {
    const consentStatus = window.Didomi.getUserStatus()
    const hasConsentItems =
      consentStatus.vendors &&
      consentStatus.vendors.consent &&
      ((consentStatus.vendors.consent.enabled &&
        consentStatus.vendors.consent.enabled.length > 0) ||
        (consentStatus.vendors.consent.disabled &&
          consentStatus.vendors.consent.disabled.length > 0))

    setConsentInteraction(!!hasConsentItems)
    return !!hasConsentItems
  }

  return {
    ConsentInteraction,
    vendorConsentStatus,
    setConsentInteraction,
    subscribeToGeneralConsentChanges,
    subscribeToVendorConsentChanges,
    checkConsentStatus,
  }
}
