import { Amplify } from 'aws-amplify'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: config.public.USERPOOL_ID,
        userPoolClientId: config.public.USERPOOL_WEB_CLIENT_ID,
      },
    },
  })

  return {
    provide: {
      auth: Amplify,
    },
  }
})
