const isNavOpen = ref(false)
const isUserNavOpen = ref(false)

export function useNavigation() {
  const toggleNavigation = () => {
    isNavOpen.value = !isNavOpen.value
  }

  const toggleUserNavigation = () => {
    isUserNavOpen.value = !isUserNavOpen.value
  }

  const closeNavigation = () => {
    isNavOpen.value = false
  }

  const closeUserNavigation = () => {
    isUserNavOpen.value = false
  }

  const closeOnEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeNavigation()
      closeUserNavigation()
    }
  }

  const closeOnClickOutside = (event: MouseEvent) => {
    const targetElement = event.target as Element
    if (!targetElement.closest('aside')) {
      closeNavigation()
      closeUserNavigation()
    }
  }

  const addEscapeListener = () => {
    window.addEventListener('keydown', closeOnEscape)
  }

  const removeEscapeListener = () => {
    window.removeEventListener('keydown', closeOnEscape)
  }

  return {
    isNavOpen,
    isUserNavOpen,
    closeOnEscape,
    closeOnClickOutside,
    addEscapeListener,
    removeEscapeListener,
    toggleNavigation,
    toggleUserNavigation,
    closeNavigation,
    closeUserNavigation,
  }
}
