/**
 * Checks if given string is a valid JSON object.
 *
 * @param input
 */
export function isValidJson(input: string) {
  try {
    JSON.parse(input)
  } catch (e) {
    return false
  }
  return true
}

/**
 * Creates a slug based on a string (Copied from old frontend)
 *
 * @param text
 */
export function slugify(text: string) {
  return String(text)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-') // remove consecutive hyphens
}

export function titleCase(text: string) {
  return text
    .toLowerCase()
    .split(' ')
    .map(str => str.charAt(0).toUpperCase() + str.substring(1))
    .join(' ')
}

export function generateChapterId(title: string): string {
  return `${slugify(title)}`
}

export function validateUUID(uuid: string): boolean {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89AB][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    uuid
  )
}

export function removeTrailingSlash(str: string): string {
  return str.endsWith('/') ? str.slice(0, -1) : str
}

export function truncateString(s: string, maxLength: number = 10): string
{
  return s.length > maxLength ? s.substring(0, maxLength) : s;
}

export function ellipsisString(s: string, maxLength: number = 10): string
{
  return s.length > maxLength ? truncateString(s, maxLength) + "..." : s;
}
